.long-width.ui-multiselect-panel {
  width: 0px;
}

.controlLabel {
  text-align: right;
  margin-top: 7px;
}

.ui-button-success:hover {
  background-color: #00965E !important;
  color: white !important;
}


.ui-button-danger:hover {
  background-color: #e91224 !important;
  color: white !important;
}

.ui-button-warning:hover {
  background-color: #ffba01 !important;
  color: white !important;
}

#btnCreateFileDelete {
  width: 25px;
  float: right;
}

.pTemplate-Output{
  width: 200px;
  word-wrap: break-word;
} 

.p-panel .p-panel-header, p-header {
  width: 100%;
}