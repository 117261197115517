<div id="dvCommercialMarketGrid" class="table-container">
    <p-table #dt [columns]="cols" id="dtCommercialMarkets" [value]="commercialMarketAllData" dataKey="CommercialMarketZoneID" sortMode="single"
        [reorderableColumns]="true" [scrollable]="true"
        columnResizeMode="fit" exportFilename="Commercial Market Zone" (onFilter)="filterCommercialMarketDataTable($event)"
        selectionMode="single" (onRowDblclick)="dt.toggleRow($event)" [loading]="loading"
        [totalRecords]="commercialMarketAllData.length" [resizableColumns]="true" [expandedRowKeys]="expandedRows" [(first)]="first">
    <ng-template pTemplate="caption" id="dtCMHeader">
      <div class="ui-helper-clearfix align-center">
        <p-button label="Commercial Market Zone Count" icon="fa fa-crop" styleClass="p-button-outlined p-button-secondary" badge="{{commercialMarketAllData.length}}" badgeClass="p-badge-secondary"></p-button>&nbsp;
        <p-button label="Filtered Count" icon="fa fa-filter" styleClass="p-button-outlined p-button-secondary" badge="{{filteredData.length}}" badgeClass="p-badge-secondary"></p-button>
        <button id="btnCMExport" type="button" pButton icon="fa fa-file-o" class="p-button-success" iconPos="left" label="Export"
          (click)="exportCSVForCommercialMarketZones()" style="float:right"></button>
      </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 1.25em"></th>
            <ng-container *ngFor="let col of columns">
              <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [pTooltip]="col.title ?? col.header">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </ng-container>
          </tr>
          <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field">
              <p-multiSelect id="msFilterCMCode" *ngSwitchCase="'CommercialMarketZoneCode'" [(ngModel)]="filteredCMCodes" [resetFilterOnHide]="true" [options]="distinctCodes"
              placeholder="All CMZs" (click)="$event.stopPropagation();" appendTo="body" (onChange)="dt.filter($event.value,col.field,'in')"
              styleClass="ui-column-filter" [style]="{'width':'100%'}" [maxSelectedLabels]="3" selectedItemsLabel="{0} items selected"></p-multiSelect>
            <input id="txtFilterCMName" *ngSwitchCase="'CommercialMarketZoneName'" [(ngModel)]="filteredCMName" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
              style="width:100%" placeholder="Search">
            <p-multiSelect id="msFilterCMRegionCode" *ngSwitchCase="'RegionCode'"  [(ngModel)]="filteredCMRegionCodes" [resetFilterOnHide]="true" [options]="distinctRegions"
              placeholder="All RCZs" (click)="$event.stopPropagation();" appendTo="body" (onChange)="dt.filter($event.value,col.field,'in')"
              styleClass="ui-column-filter" [style]="{'width':'100%'}" [maxSelectedLabels]="3" selectedItemsLabel="{0} items selected"></p-multiSelect>
            <input id="txtFilterCMDescription" *ngSwitchCase="'Description'" [(ngModel)]="filteredCMDEscription" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
              style="width:100%" placeholder="Search">
            <input id="txtFilterCMStatus" *ngSwitchCase="'RequestStatus'" [(ngModel)]="filteredCMRequestStatus" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
              style="width:100%" placeholder="Search">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
          <tr [pSelectableRow]="rowData" (dblclick)="dt.toggleRow(rowData)">
            <td style="width: 1.25em">
              <a href="#" [pRowToggler]="rowData">
                <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-circle-down' : 'pi pi-pw pi-chevron-circle-right'" style="font-size: 1.25em"></i>
              </a>
            </td>
            <ng-container *ngFor="let col of columns">
              <td *ngIf="!col.hidden">
                {{rowData[col.field]}} {{rowData["Region.Code"]}}
              </td>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template let-commercialMarket pTemplate="rowexpansion" id="rowBZexpand" let-columns="columns">
          <tr>
            <td [attr.colspan]="columns.length">
              <div class="grid">
                <div class="col">
                  <p-panel class="height" id="editCMPanel1">
                    <div class="grid grid-nogutter">
                      <div class="col-6">
                        <label id="lblCMName">CMZ Name :</label>
                      </div>
                      <div class="col-6">
                        <p style="white-space: pre-wrap" id="valCMName">{{commercialMarket.CommercialMarketZoneName}}</p>
                      </div>
                      <div class="col-6">
                        <label id="lblCMCode">CMZ Code :</label>
                      </div>
                      <div class="col-6">
                        <p style="white-space: pre-wrap" id="valCMCode">{{commercialMarket.CommercialMarketZoneCode}}</p>
                      </div>
                      <div class="col-6">
                        <label id="lblDescription">Description :</label>
                      </div>
                      <div class="col-6">
                        <p style="white-space: pre-wrap" id="valDescription">{{commercialMarket.Description}}</p>
                      </div>
                      <div class="col-6">
                        <label id="lblCropDescription">RCZ Code :</label>
                      </div>
                      <div class="col-6">
                        <p style="white-space: pre-wrap" id="valCropDescription">{{commercialMarket.RegionCode}}</p>
                      </div>
                      <div class="col-6">
                        <label id="lblStatus">Status :</label>
                      </div>
                      <div class="col-6">
                        <p style="white-space: pre-wrap" id="valStatus">{{commercialMarket.RequestStatus}}</p>
                      </div>
                    </div>
                  </p-panel>
                </div>
                <!-- <div class="col">
                  <p-panel class="height" id="editPanel2">
                    <div class="grid grid-nogutter">

                    </div>
                  </p-panel>
                </div> -->
                <div class="col">
                  <p-panel class="height" id="editPanel3">
                    <div class="grid grid-nogutter">
                      <div class="col-6">
                        <label id="lblAOIID">PRISM AOI ID :</label>
                      </div>
                      <div class="col-6">
                        <p style="white-space: pre-wrap" id="valAOIID">{{commercialMarket.PRISM_AOI_ID ? commercialMarket.PRISM_AOI_ID : '-'}}</p>
                      </div>
                      <div class="col-6">
                        <label id="lblCreatedBy">Created By :</label>
                      </div>
                      <div class="col-6">
                        <p style="white-space: pre-wrap" id="valCreatedBy">{{commercialMarket.CreatedByUserName ? commercialMarket.CreatedByUserName : '-'}}</p>
                      </div>
                      <div class="col-6">
                        <label id="lblDate">Date Created :</label>
                      </div>
                      <div class="col-6">
                        <p style="white-space: pre-wrap" id="valDate">{{ConvertDateToString(commercialMarket.CreatedOn)}}</p>
                      </div>
                      <div class="col-6">
                        <label id="lblApprovedBy">Approved By :</label>
                      </div>
                      <div class="col-6">
                        <p style="white-space: pre-wrap" id="valApprovedBy">{{commercialMarket.ApprovedByUserName ? commercialMarket.ApprovedByUserName : '-'}}</p>
                      </div>
                      <div class="col-6">
                        <label id="lblDateApproved">Date Approved :</label>
                      </div>
                      <div class="col-6">
                        <p style="white-space: pre-wrap" id="valDateApproved">{{ConvertDateToString(commercialMarket.ApprovedOn)}}</p>
                      </div>
                      <div class="col-6">
                        <label id="lblRetiredBy">Retired By :</label>
                      </div>
                      <div class="col-6">
                        <p style="white-space: pre-wrap" id="valRetiredBy">{{commercialMarket.RetiredByUserName ? commercialMarket.RetiredByUserName : '-'}}</p>
                      </div>
                      <div class="col-6">
                        <label id="lblDateRetired">Date Retired :</label>
                      </div>
                      <div class="col-6">
                        <p style="white-space: pre-wrap" id="valDateRetired">{{ConvertDateToString(commercialMarket.RetiredOn)}}</p>
                      </div>
                      <div class="col-6">
                        <label id="lblLastModifiedBy">Last Modified By :</label>
                      </div>
                      <div class="col-6">
                        <p style="white-space: pre-wrap" id="valLastModifiedBy">{{commercialMarket.ModifiedByUserName ? commercialMarket.ModifiedByUserName : '-'}}</p>
                      </div>
                      <div class="col-6">
                        <label id="lblModifiedOn">Date Last Modified :</label>
                      </div>
                      <div class="col-6">
                        <p style="white-space: pre-wrap" id="valModifiedOn">{{ConvertDateToString(commercialMarket.ModifiedOn)}}</p>
                      </div>
                    </div>
                  </p-panel>
                </div>
              </div>
              <div class="grid">
                <div class="col-8"></div>
                <div class="col-2">
                  <button id="btnCMComment" pButton type="button" style="width: 100%" (click)="viewComments(commercialMarket)"
                    label="{{commercialMarket.ApprovalComments === null || commercialMarket.ApprovalComments.length === 0 ? 'Add Comment' : 'View / Add Comment(s)'}}"
                    iconPos="left" [icon]="commercialMarket.ApprovalComments === null || commercialMarket.ApprovalComments.length === 0 ? 'fa fa-pencil' : 'fa fa-comments-o'"
                    class="p-button-info"></button>
                </div>
                <div class="col-2">
                  <div [hidden]="!((commercialMarket.CreatedBy !== null && commercialMarket.CreatedBy.Email.toLocaleLowerCase() === userName) || isROVAdmin)">
                    <button id="btnCMEdit" pButton type="button" style="width: 100%" (click)="editCommercialMarkets(commercialMarket)"
                      label="Edit" iconPos="left" icon="fa fa-fw fa-edit"></button>
                  </div>
              </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
              <td [attr.colspan]="7">
                  No records found
              </td>
          </tr>
        </ng-template>
  </p-table>
  </div>

  <p-dialog id="dlgBZComments" header="View / Add Comments" [style]="{width: '900px', height:'470px'}" [(visible)]="displayComments"
    showEffect="fade" [modal]="true">
    <view-comments [disableCommentButton]="disableCommentButton" [approvalCommentData]="approvalCommentData"
     [sourceIdentifier]="sourceIdentifier" (saveCommentEvent)="onSaveComment($event)"></view-comments>
  </p-dialog>

  <div>
    <p-dialog id="dlgCMEdit" [contentStyle]="{'overflow':'visible'}" [(visible)]="dialogVisible" [breakpoints]="{'640px':'100vw'}"
      showEffect="fade" [modal]="true" [style]="{width: '900px'}" (onHide)="onCloseEditCommercialMarket($event, frm)"
      [closeOnEscape]="false" [closable]="false" [showHeader]="true" header="Commercial Market Zone Details">
      <p-panel id="editCMPanel4" appendTo="body">
        <form #frm="ngForm" id="frmCM" [formGroup]="commercialMarketZoneFormGroup">
          <div class="grid">
            <div class="col-6">
              <div class="grid">
                  <div class="col-5 controlLabel">
                    <label id="lblCommercialMarketName">CMZ Name</label>
                  </div>
                  <div class="col-7">
                    <input pInputText id="txtCommercialMarketName" [(ngModel)]="commercialMarketZoneForm.CommercialMarketZoneName"
                      formControlName="CommercialMarketZoneName" minlength="1" maxlength="100" styleClass="width100" />
                  </div>
                  <div class="col-5 controlLabel">
                    <label id="lblCommercialMarketCode">CMZ Code</label>
                  </div>
                  <div class="col-7">
                    <input pInputText id="txtCMCode" minlength="2" maxlength="100" [(ngModel)]="commercialMarketZoneForm.CommercialMarketZoneCode"
                      formControlName="CommercialMarketZoneCode" styleClass="width100" (keydown)="commercialMarketZoneForm.PRISM_AOI_ID = ''" />
                  </div>
                  <div class="col-5 controlLabel">
                    <label for="ddCMRegionName">RCZ Code</label>
                  </div>
                  <div class="col-7">
                    <p-dropdown id="ddCMRegionName" [style]="{'width':'100%'}" appendTo="body" name="regionName"
                      [filter]=true [options]="regionName" [(ngModel)]="commercialMarketZoneForm.RegionID" formControlName="RegionID"
                      placeholder="Select a RCZ" [showClear]="true"></p-dropdown>
                  </div>
              </div>
            </div>
            <div class="col-6">
              <div class="grid">
                <div class="col-5 controlLabel">
                  <label id="lblDes">Description</label>
                </div>
                <div class="col-7">
                  <textarea id="txtCMDescription" [cols]="30" [rows]="2" minlength="1" maxlength="2000" [(ngModel)]="commercialMarketZoneForm.Description"
                    formControlName="Description" pInputTextarea styleClass="width100" class="p-inputtext"></textarea>
                </div>
                <div class="col-5 controlLabel">
                  <label for="ddCMStatus" >Status</label>
                </div>
                <div class="col-7">
                  <p-dropdown id="ddCMStatus" [style]="{'width':'100%'}" name="status" [filter]=true [options]="status"
                    [(ngModel)]="commercialMarketZoneForm.RequestStatus" formControlName="RequestStatus" placeholder="Select a Status"
                    (onChange)="statusChange(commercialMarketZoneForm.RequestStatus,true)" appendTo="body"></p-dropdown>
                </div>
                <div class="col-5 controlLabel">
                  <label for="txtCMPRISM_AOI_ID">PRISM AOI ID</label>
                </div>
                <div class="col-7">
                  <input type="number" pInputText id="txtCMPRISM_AOI_ID" [(ngModel)]="commercialMarketZoneForm.PRISM_AOI_ID"
                    formControlName="PRISM_AOI_ID" maxlength="9" styleClass="width100" readonly="readonly" />
                </div>
                <div class="col-5">
                </div>
                <div class="col-7">
                  <button type="button" id="btnCMCheckAOI" pButton icon="fa fa-eye" class="p-button-info" (click)="GetAOIID(commercialMarketZoneForm)"
                    label="Check AOI" title="Check AOI"></button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </p-panel>
      <ng-template pTemplate="footer">
          <div [hidden]="!isROVUser" style="display: inline;">
            <button type="button" id="btnCMCancel" pButton icon="fa fa-close" class="p-button-warning" (click)="close(frm)"
              label="Cancel" style='width: 200px;'>
            </button>
          </div>
          <button type="submit" id="btnCMSave" pButton [icon]="saveIcon" class="{{saveIconClass}}" (click)="saveCommercialMarket(frm, commercialMarketZoneForm.RequestStatus)"
            [disabled]="!(frm.form.valid || commercialMarketZoneForm.RequestStatus === 'Declined')" label="{{saveLabelText}}"
            style='width: 200px;'></button>
      </ng-template>
    </p-dialog>
  </div>

  <p-dialog id="dlgCMSelectAOI" header="Select AOI ID" [style]="{'top': positionTop, 'left': positionLeft, width: 'width', height:'height'}"
    [(visible)]="displayLEIDWindow" showEffect="fade" [modal]="true"
    [closeOnEscape]="true" [closable]="true" [resizable]="false">
    <app-leidpicker id="appCMLEID" [existingLEIDString]="existingLEIDString" (addLEDataClick)="onaddLEDataClick($event)"></app-leidpicker>
  </p-dialog>
