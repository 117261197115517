import { SeedStorageLocationType } from './../shared/seedStorageLocationType';
import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit, OnChanges, SimpleChange } from '@angular/core';
import { SeedStorageLocationModel } from '.././shared/seedStorageLocationModel';
import { SSLForm } from '.././shared/seedStorageLocationFormControls';
import { AppService } from '../app.service';
import { SeedStorageLocationService } from './seedstoragelocation.service';
import { Constants, } from './../shared/constants';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { CommonService } from '../shared/common.service';
import { Person } from '.././shared/person';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRequestedData } from '.././shared/userRequestedData';
import { GeoPoliticalUnitModel } from './../shared/geoPoliticalUnitModel';
import { ApprovalComments, RequestStatus } from "../shared";
import { UserClaim } from "../shared/userClaim";
import { Message } from 'primeng/api';
import { CropModel } from '../shared/cropModel';
import { SSLModel } from './../shared/sslModel'
import { Station } from './../shared/station';
import { Dropdown } from 'primeng/dropdown';
import { SharedService } from './../services/shared.service';

@Component({
  selector: 'app-seedstoragelocation',
  templateUrl: './seedstoragelocation.component.html',
  styleUrls: ['./seedstoragelocation.component.css']
})
export class SeedstoragelocationComponent implements OnInit, OnChanges{
  msgs: Message[] = [];
  status: SelectItem[] = [];
  displayComments: boolean;
  selectedItems: any[];
  public keyContactsLead: SelectItem[] = [];
  public sslFormGroup: FormGroup;
  public isROVAdmin = false;
  public isROVUser = true;
  public approvalCommentData: any[] = [];
  public _approvalComments: ApprovalComments = new ApprovalComments();

  public sslAllData: SeedStorageLocationModel[] = [];
  public filteredData: SeedStorageLocationModel[] = [];
  public dialogVisible: boolean;
  public existingPeople: Person[] = [];
  public existingPeopleString = '';
  public sslData: SeedStorageLocationModel = new SeedStorageLocationModel();
  public parentComponent = 'SSL';
  loading: boolean;

  public filteredSSLCodes: string;
  public filteredSSLCode: string;
  public filteredSSLCrop: string;
  public filteredSSSLCountry: string;
  public filteredSSLState: string;
  public filteredSeedStorageLocationKeyContactNames: string;
  public filteredSSLRequestStatus: string;

  public disableCommentButton = false;
  public sslStationName: Station[] = [];
  public allStationName: Station[] = [];
  public sslCropName: CropModel[] = [];
  public sslTypeName: SeedStorageLocationType[] = [];
  public sslName: SSLModel[] = [];
  public sslCountryName: GeoPoliticalUnitModel[] = [];
  expandedItems: any[] = [];
  public displayKeyContacts: boolean;
  public distinctCodes: SelectItem[];
  public sortedCodes: SelectItem[];
  public saveLabelText = 'Save';
  public saveIcon = 'fa fa-save';
  public saveIconClass = 'p-button-success';
  selectedStatus: string;
  sourceIdentifier: string;
  public userName: string;
  public dd: Dropdown;
  public panelHeight = '350px';
  cols: any[];
  SeedStorageLocationID: string;
  public sslForm = new SSLForm(
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    [],
    '',
    0,
    '',
    false,
    '',
    '',
    '',
    '',
    '',
    '',
    0,
    [],
    new Person,
    '',
    new Date(),
    new Person(),
    '',
    new Date(),
    new Person(),
    '',
    new Date(),
    '',
    new Person(),
    new Date(),
    ''
  );
  expandedRows: { [id: string]: boolean } = {};
  first: number = 0;

  @Input()
  public selectedTabIndex = 0;

  @Input()
  public selectedRequestID: string;

  @Input()
  public cleanCache: boolean;

  @Output()
  public cleanUpCache: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _confirmation: ConfirmationService, private route: ActivatedRoute,
    private _appService: AppService, private _sslService: SeedStorageLocationService,
    private _commonService: CommonService, private _fb: FormBuilder,
    private router: Router, private sharedservice: SharedService) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(): void {
    if (this.isROVAdmin) {
      this.sslFormGroup = this._fb.group({
        'SSLName': [this.sslForm.Name, Validators.required],
        'StationId': [this.sslForm.StationId, Validators.required],
        'StationCode': [this.sslForm.StationCode],
        'StationName': [this.sslForm.StationName],
        'SeedStorageLocationTypeId': [this.sslForm.SeedStorageLocationTypeId, this.sslForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'SeedStorageLocationTypeCode': [this.sslForm.SeedStorageLocationTypeCode],
        'SeedStorageLocationTypeName': [this.sslForm.SeedStorageLocationTypeName],
        'CropID': [this.sslForm.CropID, Validators.required],
        'CropName': [this.sslForm.CropName],

        'KeyContacts': [this.sslForm.SeedStorageLocationKeyContactNames],
        'Description': [this.sslForm.Description, Validators.required],
        'StatusId': [this.sslForm.StatusId],
        'RequestStatus': [this.sslForm.RequestStatus],
        'Virtual': [this.sslForm.IsVirtual],
        'R3BuildingCode': [this.sslForm.R3BuildingCode],
        'WWSAddessId': [this.sslForm.WWSAddessId],
        'WWSShippingProject': [this.sslForm.WWSShippingProject],
        'PrismLeId': [this.sslForm.PrismLeId]
      });

      this.sslFormGroup.controls['RequestStatus'].enable();
    } 
    else {
      this.sslFormGroup = this._fb.group({
        'SSLName': [this.sslForm.Name, Validators.required],
        'StationId': [this.sslForm.StationId, Validators.required],
        'StationCode': [this.sslForm.StationCode],
        'StationName': [this.sslForm.StationName],
        'SeedStorageLocationTypeId': [this.sslForm.SeedStorageLocationTypeId,this.sslForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'SeedStorageLocationTypeCode': [this.sslForm.SeedStorageLocationTypeCode],
        'SeedStorageLocationTypeName': [this.sslForm.SeedStorageLocationTypeName],
        'CropID': [this.sslForm.CropID, Validators.required],
        'CropName': [this.sslForm.CropName],

        'KeyContacts': [this.sslForm.SeedStorageLocationKeyContactNames],
        'Description': [this.sslForm.Description, Validators.required],
        'StatusId': [this.sslForm.StatusId],
        'RequestStatus': [this.sslForm.RequestStatus],
        'Virtual': [this.sslForm.IsVirtual],
        'R3BuildingCode': [this.sslForm.R3BuildingCode],
        'WWSAddessId': [this.sslForm.WWSAddessId],
        'WWSShippingProject': [this.sslForm.WWSShippingProject],
        'PrismLeId': [this.sslForm.PrismLeId]
      });

      this.sslFormGroup.controls['RequestStatus'].disable();
    }
  }

  getLoginInfo() {
    let localRoles = this.sharedservice.getSessionStorageValue(Constants.UIUSERROLE);
    if (localRoles !== '' && localRoles !== null) {
      const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
      this.userName = userClaim.username;
      this.isROVAdmin = userClaim.isROVAdmin;
      this.isROVUser = userClaim.isROVUser;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedTabIndex === 2) {
      this.getLoginInfo();
      this.buildForm();

      if( (this.cleanCache || (this.sslAllData == null || this.sslAllData.length === 0))
      && (changes['selectedTabIndex'] && changes['selectedTabIndex'].currentValue === 2)) {
        this.route.queryParams.subscribe(p=>{
          const seedStorageLocationID: string = p['SeedStorageLocationID'];
          this.selectedRequestID = seedStorageLocationID ?? '';
        })

        this.cols = [
          { field: 'SeedStorageLocationID', header: 'SSL ID', hidden: 'true' },
          { field: 'Name', header: 'SSL Name', title: 'Seed Storage Location Name' },
          { field: 'Station.StationCode', header: 'R&D Center' },
          { field: 'CropDescription', header: 'Crop' },
          { field: 'Country', header: 'Country' },
          { field: 'State', header: 'State' },
          { field: 'SeedStorageLocationKeyContactNames', header: 'Key Contact' },
          { field: 'RequestStatus', header: 'Status' }
        ];

        this.getSSL();
        this.GetAllCountriesFomPRISM();
      }
    }
  }

  filterSSLDataTable(event: any) {
    this.filteredData = event.filteredValue;
  }

  getSSL() {
    this.loading = true;
    let localRoles = this.sharedservice.getSessionStorageValue(Constants.UIUSERROLE);
    if (localRoles !== '' && localRoles !== null) {
      const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
      this.isROVAdmin = userClaim.isROVAdmin;
      this.isROVUser = userClaim.isROVUser;

      this._sslService.getSSLResult().subscribe({
        next: response => {
          response.forEach(e => {
            e.Country = e.Station.Country;
            e.State = e.Station.State;
          })
          this.filteredData = this.sslAllData = response;

          if (response != null && this.selectedRequestID !== '') {
            const selectedItem = response.find(element => element.SeedStorageLocationID.toLowerCase() === this.selectedRequestID.toLowerCase());
            this.first = 0;
            if(!selectedItem){
              this.DisplayInfoMessage('Info', `The Seed Storage Location
              requested in the URL either doesn't exist or is no more in Pending State.`);
            }
            else{
              let index = response.indexOf(selectedItem);
              const pageNumber = Math.ceil(++index / 10)
              this.first = (pageNumber - 1) * 10;
              this.expandedRows[this.selectedRequestID.toLowerCase()] = true;
              this.editSSL(selectedItem);
            }
          }
          this.distinctSSLCodesDropdown();
        },
        error: (error) => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        }
      });
    }
  }

  distinctSSLCodesDropdown() {
    const items = this.sslAllData;
    let flags: { [code: string]: boolean } = {};
    const l = items.length;
    this.distinctCodes = [];
    for (let i = 0; i < items.length; i++) {
      if (!flags[items[i].Name]) {
        flags[items[i].Name] = true;
        this.distinctCodes.push({
          label: items[i].Name,
          value: items[i].Name
        });
      }
    }
    if (this.distinctCodes) {
      this.sortedCodes = this.distinctCodes.sort((a, b) => {
        if (a.value.toLowerCase() < b.value.toLowerCase()) {
          return -1;
        } else if (a.value.toLowerCase() > b.value.toLowerCase()) {
          return 1;
        } else {
          return 0;
        };
      });
    }
  }

  onaddUserDataClick(data: UserRequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayKeyContacts = false;
      let leadNames = '';

      this.sslForm.SeedStorageLocationKeyContacts = [];
      this.sslForm.SeedStorageLocationKeyContactNames = '';
      this.keyContactsLead = [];

      data.person.forEach(pr => {
        if (!this.sslForm.SeedStorageLocationKeyContacts.find(s => s.Person.LoginName === pr.LoginName)) {
          this.sslForm.SeedStorageLocationKeyContacts.push({
            ID: '',
            PersonID: pr.PersonID, Person: pr, SeedStorageLocation: null, SeedStorageLocationID: this.sslData.SeedStorageLocationID
          });
          this.keyContactsLead.push({ label: pr.DisplayName, value: pr.DisplayName });
        }

        leadNames = leadNames + pr.DisplayName + '; ';
      });

      leadNames = leadNames.trim();

      if (leadNames.lastIndexOf(';') === leadNames.length - 1) {
        leadNames = leadNames.substring(0, leadNames.lastIndexOf(';'));
      }
      if (this.keyContactsLead) {
        const x = document.querySelector('#lstSSLLeads > div');
        (x as HTMLElement).style.borderBottom = '1px solid';
        (x as HTMLElement).style.borderBottomColor = '#c0c0c0';
      } else {
        const x = document.querySelector('#lstSSLLeads > div');
        (x as HTMLElement).style.borderBottomColor = '3px solid #a94442';
      }
      this.sslForm.SeedStorageLocationKeyContactNames = leadNames;
      this.existingPeopleString = '';
    }
  }

  onCloneSSL(seedStorageLocation: SeedStorageLocationModel)
  {
    if(seedStorageLocation.RequestStatus === RequestStatus.PENDING || seedStorageLocation.RequestStatus === RequestStatus.ACTIVE) {
        seedStorageLocation.PRISM_AOI_ID = 0;

      localStorage.setItem("ToBeClonedSSL", JSON.stringify(seedStorageLocation));
      this.router.navigate(['/create','SeedStorageLocation']);
    }
    else {
      this.DisplayInfoMessage('Cloning', 'Only Active or Pending records can be cloned');
    }
  }

  onEditSSLShow(){
    if (this.keyContactsLead.length !== 0) {
      const x = document.querySelector('#lstSSLLeads > div');
      (x as HTMLElement).style.borderBottom = '1px solid';
      (x as HTMLElement).style.borderBottomColor = '#c0c0c0';
    } else {
      const x = document.querySelector('#lstSSLLeads > div');
      (x as HTMLElement).style.borderBottomColor = '3px solid #a94442';
    }
  }

  editSSL(seedStorageLocation: SeedStorageLocationModel) {
    this.buildForm();
    this.sslForm = Object.assign({}, seedStorageLocation);
    this.getCrops();
    this.getSSLTypes();
    this.getStationsByCropID();

    this.dialogVisible = true;

    this.status = [];
    this.keyContactsLead = [];
    if (seedStorageLocation.SeedStorageLocationKeyContacts != null) {
      seedStorageLocation.SeedStorageLocationKeyContacts.forEach(pr => {
        this.keyContactsLead.push({ label: pr.Person.DisplayName, value: pr.Person.DisplayName });
      });
    }

    this.status.push({ label: RequestStatus.ACTIVE, value: RequestStatus.ACTIVE });
    this.status.push({ label: RequestStatus.DECLINED, value: RequestStatus.DECLINED });
    this.status.push({ label: RequestStatus.PENDING, value: RequestStatus.PENDING });

    if (seedStorageLocation.RequestStatus !== RequestStatus.PENDING) {
      let index = this.status.findIndex(s => s.value === RequestStatus.DECLINED);

      if (index !== -1) {
        this.status.splice(index, 1);
      }

      index = this.status.findIndex(s => s.value === RequestStatus.PENDING);

      if (index !== -1) {
        this.status.splice(index, 1);
      }

      this.status.push({ label: RequestStatus.RETIRED, value: RequestStatus.RETIRED });
      this.status.push({ label: RequestStatus.TRANSITIONING, value: RequestStatus.TRANSITIONING });
    }

    this.selectedStatus = this.status.find(s => s.label === seedStorageLocation.RequestStatus)?.value;
    this.sslForm.RequestStatus = this.selectedStatus;
    this.statusChange(this.sslForm.RequestStatus,false);

    this.sslForm.SeedStorageLocationID = seedStorageLocation.SeedStorageLocationID;
    this.sslForm.BaseSeedStorageLocationID = seedStorageLocation.BaseSeedStorageLocationID;

    this.sslForm.ApprovalComments = [];
  }

  setSaveButtonStyle(status: string) {
    if (status === RequestStatus.PENDING) {
      this.saveIcon = 'fa fa-save';
      this.saveIconClass = 'p-button-primary';
      this.saveLabelText = 'Save';
    } else if (status === RequestStatus.ACTIVE) {
      this.saveIcon = 'fa fa-check';
      this.saveIconClass = 'p-button-secondary';
      this.saveLabelText = 'Mark as Active';
    } else if (status === RequestStatus.DECLINED) {
      this.saveIcon = 'fa fa-trash';
      this.saveIconClass = 'p-button-danger';
      this.saveLabelText = 'Mark as Declined';
    } else if (status === RequestStatus.RETIRED) {
      this.saveIcon = 'fa fa-ban';
      this.saveIconClass = 'p-button-danger';
      this.saveLabelText = 'Mark as Retired';
    } else if (status === RequestStatus.TRANSITIONING) {
      this.saveIcon = 'fa fa-save';
      this.saveIconClass = 'p-button-info';
      this.saveLabelText = 'Mark as Transitioning';
    }

    this.saveIconClass = this.saveIconClass + ' p-button p-widget p-state-default p-corner-all p-button-text-icon-left';
  }

  statusChange(status: string,isChange:boolean) {
    this.setSaveButtonStyle(status);
    if (status === this.selectedStatus) {
      this.saveLabelText = 'Save';
    }
    if (this.sslForm.RequestStatus === RequestStatus.RETIRED || this.sslForm.RequestStatus === RequestStatus.TRANSITIONING) {
      this.sslFormGroup = this._fb.group({
        'SSLName': [this.sslForm.Name, Validators.required],
        'StationId': [this.sslForm.StationId],
        'StationCode': [this.sslForm.StationCode],
        'StationName': [this.sslForm.StationName],
        'SeedStorageLocationTypeId': [this.sslForm.SeedStorageLocationTypeId],
        'SeedStorageLocationTypeCode': [this.sslForm.SeedStorageLocationTypeCode],
        'SeedStorageLocationTypeName': [this.sslForm.SeedStorageLocationTypeName],
        'CropID': [this.sslForm.CropID],
        'CropName': [this.sslForm.CropName],
        'KeyContacts': [this.sslForm.SeedStorageLocationKeyContactNames],
        'Description': [this.sslForm.Description],
        'StatusId': [this.sslForm.StatusId],
        'RequestStatus': [this.sslForm.RequestStatus],
        'Virtual': [this.sslForm.IsVirtual],
        'R3BuildingCode': [this.sslForm.R3BuildingCode],
        'WWSAddessId': [this.sslForm.WWSAddessId],
        'WWSShippingProject': [this.sslForm.WWSShippingProject],
        'PrismLeId': [this.sslForm.PrismLeId]
      });
    } 
    else {
      this.buildForm();
    }

    if(isChange)
      this.sslFormGroup.markAsDirty();
  }

  saveSSL(frm: any, status: any) {
    if (status === RequestStatus.DECLINED) {
      this._confirmation.confirm({
        message: Constants.CONFIRMDECLINE,
        accept: () => {
          this.save(frm, status);
        }
      });
    } else {
      this.save(frm, status);
    }
  }

  save(frm: any, status: any) {
    const crop = this.sslCropName.find(s => s.value === this.sslForm.CropID);

    if (crop) {
      this.sslForm.CropID = crop.CropID;
      this.sslForm.CropName = crop.CropName;
      this.sslForm.CropDescription = crop.CropDescription;
    } else {
      this.sslForm.CropID = '';
      this.sslForm.CropName = '';
      this.sslForm.CropDescription = '';
    }

    const sslType = this.sslTypeName.find(s => s.value === this.sslForm.SeedStorageLocationTypeId);

    if (sslType) {
      this.sslForm.SeedStorageLocationTypeId = sslType.Id;
      this.sslForm.SeedStorageLocationTypeCode = sslType.Code;
      this.sslForm.SeedStorageLocationTypeName = sslType.Name;
    }

    this.sslForm.SeedStorageLocationType = sslType;

    this.existingPeople = [];
    if (this.sslForm.SeedStorageLocationKeyContacts) {
      this.sslForm.SeedStorageLocationKeyContacts.forEach(s => {
        this.existingPeople.push(s.Person);
      });
    }

    if (this.sslForm.IsVirtual === null || this.sslForm.IsVirtual === undefined) {
      this.sslForm.IsVirtual = false;
    }

    this.sslForm.Station = this.sslStationName.find(s => s.value === this.sslForm.StationId);
    this.sslData = Object.assign({}, this.sslForm);

    if (status === RequestStatus.DECLINED || this.validateForm(status !== RequestStatus.PENDING && status !== RequestStatus.DECLINED)) {
      this.sslData.RequestStatus = status;

      this._sslService.saveSSL(JSON.stringify(this.sslData))
        .subscribe({
         next:  Result => {
            if (Result.Status === 'SUCCESS') {
              const primaryID = this.sslData.SeedStorageLocationID;
              this.sslData = Result.SeedStorageLocation;

              const i = this.sslAllData.findIndex(s => s.SeedStorageLocationID === primaryID);
              const j = this.expandedItems.findIndex(s => s.SeedStorageLocationID === primaryID);
              const k = this.sslName.findIndex(s => s.Id === primaryID);

              if (Result.SeedStorageLocation.RequestStatus === RequestStatus.DECLINED) {
                if (i !== -1) {
                  this.sslAllData.splice(i, 1);
                }
                if (j !== -1) {
                  this.expandedItems.splice(j, 1);
                }
                if (k !== -1) {
                  this.sslName.splice(k, 1);
                }
              } else {
                this.sslAllData[i] = this.sslData;
                this.sslAllData[i].State = this.sslData.Station.State;
                this.sslAllData[i].Country = this.sslData.Station.Country;
                this.expandedItems[j] = this.sslData;

                if (k !== -1) {
                  this.sslName.splice(k, 1,
                    ({
                      Id: this.sslData.SeedStorageLocationID,
                      Name: this.sslData.Name,
                      Description: this.sslData.Description,
                      CropID: this.sslData.CropID,
                      RequestStatus: this.sslData.RequestStatus,
                      label: this.sslData.Name,
                      value: this.sslData.SeedStorageLocationID
                    }));
                }

                this.cleanUpCache.emit(true);
              }

              this.sslName = this.sslName.slice();
              this.expandedItems = this.expandedItems.slice();
              this.sslAllData = this.sslAllData.slice();
              this.distinctSSLCodesDropdown();

              if (this.selectedStatus === RequestStatus.PENDING && Result.SeedStorageLocation.RequestStatus !== RequestStatus.PENDING) {
                this._appService.sendPendingCount(-1);
              }

              this.selectedStatus = status;
              this.DisplaySuccessMessage('Success', Result.Message);
            } else {
              this.sslData.RequestStatus = this.selectedStatus;
              this.DisplayErrorMessage('API Error', Result.Message);
            }
            this.dialogVisible = false;
          },
          error: error => {
            this.sslData.RequestStatus = this.selectedStatus;
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
          }
    });
    }
  }

  validateForm(isApprove: any) {
    if (!this.sslForm.Name || this.sslForm.Name.toString().trim() === '') {
      this.DisplayWarningMessage('Warning', 'Seed Storage Location Name is required...');
      return false;
    }

    if ((this.sslForm.Name
      && (this.sslForm.Name.trim().length < 2 || this.sslForm.Name.trim().length > 4))) {
      this.DisplayWarningMessage('Warning', 'Seed Storage Location Name should be between 2 and 4 characters long...');
      return false;
    }

    if ((!this.sslForm.CropName || this.sslForm.CropName === '')
      && this.sslForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'Crop is required...');
      return false;
    }

    if (this.sslForm.Name && this.sslForm.Name.toString().trim() !== '') {
      const sCode = this.sslName.find(s => s.Name.toString().toLowerCase()
        === this.sslForm.Name.toString().toLowerCase() && s.CropID === this.sslForm.CropID
        && s.Id !== this.sslForm.SeedStorageLocationID && s.RequestStatus !== RequestStatus.RETIRED);
      if (sCode && this.sslForm.RequestStatus !== RequestStatus.RETIRED) {
        this.DisplayWarningMessage('Warning', 'This SSL Name and Crop combo already exists. Please enter a new Name or Crop...');
        return false;
      }
    }

    if ((!this.sslForm.Description || this.sslForm.Description.toString().trim() === '')
      && this.sslForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'Description is required...');
      return false;
    }

    if ((!this.sslForm.Station
      || (this.sslForm.Station && this.sslForm.Station.StationName === ''))
      && this.sslForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'R&D Center Name is required...');
      return false;
    }

    if (this.sslForm.WWSShippingProject && (this.sslForm.WWSShippingProject.toString().length > 255)) {
      this.DisplayWarningMessage('Warning', 'Allowed Max length is 255 characters for WWS Shipping Project...');
      return false;
    }

    return true;
  }

  addLeads() {
    this.existingPeople = [];

    if (this.sslForm.SeedStorageLocationKeyContacts) {
      this.sslForm.SeedStorageLocationKeyContacts.forEach(Investigator => {
        this.existingPeople.push(Investigator.Person);
      });
    }

    const obj = new UserRequestedData();
    obj.ParentComponent = this.parentComponent;
    obj.person = this.existingPeople;
    obj.singleLead = false;
    this.existingPeopleString = JSON.stringify(obj);
    this.displayKeyContacts = true;
  }

  getCrops() {
    this._commonService.getCrops().subscribe({
      next: (data: CropModel[]) => {
        this.sslCropName = data;
        this.sslCropName.forEach(element => {
          element.value = element.CropID;
          element.label = element.CropDescription;
        });
      },
      error: error => {
        this.DisplayErrorMessage('Error', Constants.NOSERVICE);
      }
  });
  }

  getSSLTypes() {
    this._commonService.getSSLTypes().subscribe({
      next: (data: SeedStorageLocationType[]) => {
        this.sslTypeName = data;
        this.sslTypeName.forEach(element => {
          element.value = element.Id;
          element.label = element.Name;
        });
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
    });
  }

  clearSSLType() {
    this.sslForm.SeedStorageLocationTypeId = '';
    this.sslForm.SeedStorageLocationTypeCode = '';
    this.sslForm.SeedStorageLocationTypeName = '';
  }

  GetAllCountriesFomPRISM() {
    this.sslCountryName = [];
    this._sslService.GetAllCountriesFomPRISM().subscribe({
      next: (data: { SeedStorageLocationNames: any[]; Countries:  any[]; Stations:  any[]; }) => {
        if (data.SeedStorageLocationNames && data.SeedStorageLocationNames.length > 0) {
          this.sslName = [];

          data.SeedStorageLocationNames.forEach(item => {
            this.sslName.push({
              Id: item.Id,
              Name: item.Name,
              CropID: item.CropId,
              Description: item.Description,
              RequestStatus: item.RequestStatus,
              label: item.Name,
              value: item.Id
            });
          });
        }

        if (data.Countries && data.Countries.length > 0) {
          this.sslCountryName = data.Countries;
          this.sslCountryName.forEach(element => {
            element.value = element.AoiId;
            element.label = element.Name;
          });
        }

        if (data.Stations && data.Stations.length > 0) {
          this.sslStationName = data.Stations;
          this.sslStationName.forEach(element => {
            element.value = element.StationID;
            element.label = element.StationCode + ' - ' + element.StationName;
          });
        }
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
     });
  }

  getStationsByCropID() {
    this.allStationName = [];
    const cropID = this.sslForm.CropID;

    this.allStationName = this.sslStationName.filter(s => s.StationCrops.find(element => element.CropID
      === cropID));
  }

  onCloseEditSSL(event: any, frm: any) {
     setTimeout( () => { }, 100);
  }

  close(frm: any) {
    if (frm.dirty) {
      this._confirmation.confirm({
        message: Constants.PENDINGCHANGES,
        accept: () => {
          this.dialogVisible = false;
          this.existingPeople = [];
          this.existingPeopleString = '';
          this.buildForm();
        }
      });
    } else {
      this.dialogVisible = false;
      this.existingPeople = [];
      this.existingPeopleString = '';
      this.buildForm();
    }
  }

  viewComments(sSL: SeedStorageLocationModel) {
    this.approvalCommentData = [];

    if (sSL.ApprovalComments != null) {
      sSL.ApprovalComments.forEach(element => {
        this.approvalCommentData.push({
          CommentDateString: this.ConvertDateToString(element.CommentDate),
          CommentDate: element.CommentDate,
          Commenter: element.Commenter.DisplayName,
          Comment: element.Comment
        });
      });
    }

    this.approvalCommentData = this.approvalCommentData.slice();
    this.displayComments = true;
    this.SeedStorageLocationID = sSL.SeedStorageLocationID;

    this.sourceIdentifier = sSL.SeedStorageLocationID;
  }

  onSaveComment(data: any){
    this.saveComment(data.comment, data.sourceIdentifier);
  }

  saveComment(comment: string, identifier: string) {
    const sslID = identifier;

    this.disableCommentButton = true;

    const approvalComments = new ApprovalComments();
    approvalComments.Comment = comment;
    approvalComments.RequestTypeID = sslID;

    this._sslService.saveSSLComment(JSON.stringify(approvalComments))
      .subscribe({
        next: Result => {
          if (Result.Status === 'SUCCESS') {
            const sslIndex = this.sslAllData
              .findIndex(s => s.SeedStorageLocationID.toString() === sslID.toString());

            if (sslIndex !== -1) {
              this.sslAllData[sslIndex] = Result.SeedStorageLocation;
              this.sslAllData[sslIndex].ApprovalComments = Result.SeedStorageLocation.ApprovalComments.slice();
              this.sslAllData = this.sslAllData.slice();

              const expandedIndex = this.expandedItems
                .findIndex(s => s.SeedStorageLocationID = Result.SeedStorageLocation.SeedStorageLocationID);

              this.expandedItems[expandedIndex] = Result.SeedStorageLocation;
              this.expandedItems = this.expandedItems.slice();

              this.approvalCommentData = [];

              if (Result.SeedStorageLocation.ApprovalComments != null) {
                Result.SeedStorageLocation.ApprovalComments.forEach((element: { CommentDate: Date; Commenter: any; Comment: any; }) => {
                  this.approvalCommentData.push({
                    CommentDateString: this.ConvertDateToString(element.CommentDate),
                    CommentDate: element.CommentDate,
                    Commenter: element.Commenter.DisplayName,
                    Comment: element.Comment
                  });
                });
              }
            }

            this.approvalCommentData = this.approvalCommentData.slice();
            this.DisplaySuccessMessage('Success', Result.Message);
            this.displayComments = false;
          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
          this.disableCommentButton = false;
        },
       error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
          this.disableCommentButton = false;
        }
  });
  }

  ConvertDateToString(dt: Date): string {
    return this._commonService.ConvertDateToString(dt);
  }

  expandRow(item: number) {
    let exists = false;

    if (exists) {
      this.expandedItems.pop();
    } 
    else {
      this.expandedItems.push(this.sslAllData[item]);
    }
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }

  exportCSVForSSL() {
    if (this.filteredData == null || this.filteredData.length === 0) {
      this.DisplayErrorMessage('Error', Constants.NOTHINGTOEXPORT);
      return;
    }

    const exportData = this.filteredData;
    this._sslService.exportCSVForSSL(JSON.stringify(exportData))
      .subscribe({
         next: data => {
          const blob = new Blob([data], { type: 'application/ms-excel'});
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = window.URL.createObjectURL(blob);
          link.download = 'SSLData.xlsx';
          document.body.appendChild(link);
          link.click();

          setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
          }, 100);
        },
       error: error => {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        }
      });
  }

  public setHeight() {
    const viewPortHeight = document.documentElement.clientHeight;
    if (viewPortHeight <= 650) {
      return 10;
    } else {
      this.panelHeight = '505px';
      return 10;
    }
  }

  clearStation() {
    this.sslForm.StationId = '';
  }

  clearCrop() {
    this.sslForm.CropID = '';
    this.clearStation();
  }
}