<div id="dvBreedingZoneGrid" class="table-container">
  <p-table #dt [columns]="cols" id="dtBreedingZones" [value]="breedingZoneAllData" dataKey="BreedingZoneID" sortMode="single"
      [scrollable]="true"
      columnResizeMode="fit" exportFilename="Breeding Zone" (onFilter)="filterBreedingZoneDataTable($event)"
      selectionMode="single" (onRowDblclick)="dt.toggleRow($event)" [loading]="loading"
      [totalRecords]="breedingZoneAllData.length" [resizableColumns]="true" [expandedRowKeys]="expandedRows" [(first)]="first">
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix align-center">
        <p-button label="Breeding Zone Count" icon="fa fa-wrench" styleClass="p-button-outlined p-button-secondary" badge="{{breedingZoneAllData.length}}" badgeClass="p-badge-secondary"></p-button>&nbsp;
        <p-button label="Filtered Count" icon="fa fa-filter" styleClass="p-button-outlined p-button-secondary" badge="{{filteredData.length}}" badgeClass="p-badge-secondary"></p-button>
        <button id="btnBZExport" type="button" pButton icon="fa fa-file-o" class="p-button-success" iconPos="left"
          label="Export" (click)="exportCSVForBreedingZones()" style="float:right"></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 1.25em"></th>
        <ng-container *ngFor="let col of columns">
          <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [pTooltip]="col.title ?? col.header">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </ng-container>
      </tr>
      <tr>
        <th *ngFor="let col of columns" [ngSwitch]="col.field">
          <p-multiSelect id="msFilterBZCode" *ngSwitchCase="'BreedingZoneCode'" [resetFilterOnHide]="true"
            [options]="distinctCodes" [optionValue]="'value'" placeholder="All BZs" (click)="$event.stopPropagation();"
            appendTo="body" (onChange)="dt.filter($event.value,col.field,'in')" styleClass="ui-column-filter"
            [style]="{'width':'100%'}" [maxSelectedLabels]="3" selectedItemsLabel="{0} items selected"></p-multiSelect>
          <input id="txtFilterBZName" *ngSwitchCase="'BreedingZoneName'" pInputText type="text"
            (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
            placeholder="Search">
          <input id="txtFilterBZCropName" *ngSwitchCase="'CropDescription'" pInputText type="text"
            (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
            placeholder="Search">
          <input id="txtFilterRegionCRMLower" *ngSwitchCase="'CRMLower'" pInputText type="text"
            (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
            placeholder="Search">
          <input id="txtFilterRegionCRMUpper" *ngSwitchCase="'CRMUpper'" pInputText type="text"
            (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
            placeholder="Search">
          <input id="txtFilterBZLeads" *ngSwitchCase="'BreedingZoneLeadNames'" pInputText type="text"
            (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
            placeholder="Search">
          <input id="txtFilterStationStatus" *ngSwitchCase="'RequestStatus'" pInputText type="text"
            (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
            placeholder="Search">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
      <tr [pSelectableRow]="rowData" (dblclick)="dt.toggleRow(rowData)">
        <td style="width: 1.25em">
          <a href="#" [pRowToggler]="rowData">
            <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-circle-down' : 'pi pi-pw pi-chevron-circle-right'"
              style="font-size: 1.25em"></i>
          </a>
        </td>
        <ng-container *ngFor="let col of columns">
          <td *ngIf="!col.hidden">
            {{rowData[col.field]}}
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template let-breedingZone pTemplate="rowexpansion" id="rowBZexpand" let-columns="columns">
      <tr>
        <td [attr.colspan]="columns.length">
          <div class="grid">
            <div class="col">
              <p-panel class="height" id="editBZPanel1">
                <div class="grid grid-nogutter">
                  <div class="col-6">
                    <label id="lblBreedingZoneName">Breeding Zone Name :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valBZName">{{breedingZone.BreedingZoneName}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblBreedingZoneCode">Breeding Zone Code :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valBZCode">{{breedingZone.BreedingZoneCode}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblBreedingZoneLead"> Breeding Zone Lead :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valBZLead" *ngFor="let item of breedingZone.BreedingZoneLeadNames.split(';')">{{item}}</p>
                  </div>
                  <div class="col-6">
                    <label style="word-wrap: break-word" id="lblDescription">Description : </label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valDescription">{{breedingZone.Description}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblCrop">Crop :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valCropDesc">{{breedingZone.CropDescription}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblStatus">Status :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valStatus">{{breedingZone.RequestStatus}}</p>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="col">
              <p-panel class="height" id="editBZPanel2 ">
                <div class="grid grid-nogutter">
                  <div class="col-6">
                    <label id="lblBreedingZoneType">Breeding Zone Type :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valBZType">{{breedingZone.BreedingZoneTypeCode}} -
                      {{breedingZone.BreedingZoneTypeName}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblcolor">Color :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valColor">{{breedingZone.Color ? breedingZone.Color : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblCRMUpper">CRM Upper :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valCRMUpper">{{breedingZone.CRMUpper ? breedingZone.CRMUpper : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblCRMLower">CRM Lower :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valCRMLower">{{breedingZone.CRMLower ? breedingZone.CRMLower : '-'}}</p>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="col">
              <p-panel class="height" id="editPanel3">
                <div class="grid grid-nogutter">
                  <div class="col-6">
                    <label id="lblPRISMAOIID">PRISM AOI ID :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valPRISMAOIID">{{breedingZone.PRISM_AOI_ID ? breedingZone.PRISM_AOI_ID : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblCreatedBy">Created By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valCreatedBy">{{breedingZone.CreatedByUserName}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblCreatedOn">Date Created :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valCreatedOn">{{ConvertDateToString(breedingZone.CreatedOn)}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblApprovedBy">Approved By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valApprovedBy">{{breedingZone.ApprovedByUserName ? breedingZone.ApprovedByUserName : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblDateApproved">Date Approved :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valDateApproved">{{ConvertDateToString(breedingZone.ApprovedOn)}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblRetiredBy">Retired By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valRetiredBy">{{breedingZone.RetiredByUserName ? breedingZone.RetiredByUserName : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblDateRetired">Date Retired :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valDateRetired">{{ConvertDateToString(breedingZone.RetiredOn)}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblLastModifiedBy">Last Modified By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valLastModifiedBy">{{breedingZone.ModifiedByUserName ? breedingZone.ModifiedByUserName : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblDateLastModified">Date Last Modified :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valDateLastModified">{{ConvertDateToString(breedingZone.ModifiedOn)}}</p>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
          <div class="grid">
            <div class="col-8"></div>
            <div class="col-2">

              <button id="btnBZComments" pButton type="button" style="width: 100%"
                (click)="viewComments(breedingZone)"
                label="{{breedingZone.ApprovalComments === null || breedingZone.ApprovalComments.length === 0 ? 'Add Comment' : 'View / Add Comment(s)'}}"
                iconPos="left"
                [icon]="breedingZone.ApprovalComments === null || breedingZone.ApprovalComments.length === 0 ? 'fa fa-pencil' : 'fa fa-comments-o'"
                class="p-button-info"></button>
            </div>

            <div class="col-2">
              <div
                [hidden]="!((breedingZone.CreatedBy !== null && breedingZone.CreatedBy.Email.toLocaleLowerCase() === userName) || isROVAdmin)">
                <button id="btnBZEdit" pButton type="button" style="width: 100%"
                  (click)="editBreedingZones(breedingZone)" label="Edit" iconPos="left" icon="fa fa-fw fa-edit"></button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
          <td [attr.colspan]="7">
              No records found
          </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog id="dlgBZComments" header="View / Add Comments" [style]="{width: '900px', height:'470px'}"
  [(visible)]="displayComments" showEffect="fade" [modal]="true">
  <view-comments [disableCommentButton]="disableCommentButton" [approvalCommentData]="approvalCommentData"
   [sourceIdentifier]="sourceIdentifier" (saveCommentEvent)="onSaveComment($event)"></view-comments>
</p-dialog>

<p-dialog id="dlgBZEdit" [contentStyle]="{'overflow':'visible'}" [(visible)]="dialogVisible" [breakpoints]="{'640px':'100vw'}"
  showEffect="fade" [modal]="true" [style]="{width: '850px'}" (onHide)="onCloseEditBreedingZone($event, frm)"
  [closable]="false" [closeOnEscape]="false" [showHeader]="true" header="Breeding Zone Details">
  <p-panel id="editBZPanel4" appendTo="body">
    <form #frm="ngForm" id="frmBZ" [formGroup]="breedingZoneFormGroup">
      <div class="grid">
        <div class="col-6">
          <div class="grid">
            <div class="col-5 controlLabel">
              <label id="breedingZoneName">Breeding Zone Name</label>
            </div>
            <div class="col-7">
              <input pInputText id="txtBreedingZoneName" [(ngModel)]="breedingZoneForm.BreedingZoneName"
                formControlName="BreedingZoneName" minlength="1" maxlength="25" styleClass="width100" />
            </div>
            <div class="col-5 controlLabel">
              <label id="breedingZoneCode">Breeding Zone Code</label>
            </div>
            <div class="col-7">
              <input pInputText id="txtBreedingZoneCode" minlength="2" maxlength="4" [(ngModel)]="breedingZoneForm.BreedingZoneCode"
                formControlName="BreedingZoneCode" styleClass="width100" (keydown)="breedingZoneForm.PRISM_AOI_ID = ''" />
            </div>
            <div class="col-5 controlLabel">
              <label id="crop">Crop </label>
            </div>
            <div class="col-7">
              <p-dropdown id="ddBZCrop" [style]="{'width':'100%'}" name="breedingZoneCropName" [filter]=true
                [options]="breedingZoneCropName" [(ngModel)]="breedingZoneForm.CropID" formControlName="CropID"
                placeholder="Select a Crop" [showClear]="true"></p-dropdown>
            </div>
            <div class="col-5 controlLabel">
              <label id="description">Description</label>
            </div>
            <div class="col-7">
              <textarea id="txtBZDescription" [cols]="30" [rows]="2" [(ngModel)]="breedingZoneForm.Description"
                formControlName="Description" pInputTextarea styleClass="width100" class="p-inputtext"></textarea>
            </div>
            <div class="col-5 controlLabel">
              <label for="txtBZColor" id="colorLbl">Color</label>
            </div>
            <div class="col-7">
              <input id="txtBZColor" type="number" pInputText  [(ngModel)]="breedingZoneForm.Color"
                formControlName="Color" maxlength="9" styleClass="width100" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="grid">
            <div class="col-5 controlLabel">
              <label for="lstBZBZLead">Breeding Zone Lead</label>
            </div>
            <div class="col-7">
              <div class="grid">
                <div class="col-10">
                  <p-listbox id="lstBZBZLead" [options]="bzLead" optionLabel="label" [(ngModel)]="breedingZoneForm.BreedingZoneLeadNames"
                    formControlName="BreedingZoneLeadNames" styleClass="width100" [style]="{'min-height':'35px'}"
                    [listStyle]="{'max-height':'100px'}">
                  </p-listbox>
                </div>
                <div class="col-2">
                  <button id="btnBZAddLead" type="button" pButton icon="fa fa-user-plus" class="p-button-info"
                    style="width: 30px" (click)="addLeads()" title="Add Breeding Zone Lead"></button>
                </div>
              </div>
            </div>
            <div class="col-5 controlLabel">
              <label id="breedingZoneTypeName">Breeding Zone Type</label>
            </div>
            <div class="col-7">
              <p-dropdown id="ddBZType" [style]="{'width':'100%'}" name="breedingZoneTypeId" [filter]=true
              [options]="breedingZoneTypeName" [(ngModel)]="breedingZoneForm.BreedingZoneTypeID"
              formControlName="BreedingZoneTypeID" placeholder="Select a Breeding Zone Type" [showClear]="true"></p-dropdown>
            </div>
            <div class="col-5 controlLabel">
              <label id="CRMUpper">CRM Upper</label>
            </div>
            <div class="col-7">
              <input id="txtBZCRMUpper" type="number" pInputText [(ngModel)]="breedingZoneForm.CRMUpper"
                formControlName="CRMUpper" minlength="0" maxlength="4" styleClass="width100" />
            </div>
            <div class="col-5 controlLabel">
              <label id="CRMLower">CRM Lower</label>
            </div>
            <div class="col-7">
              <input id="txtBZCRMLower" type="number" pInputText [(ngModel)]="breedingZoneForm.CRMLower"
                formControlName="CRMLower" minlength="0" maxlength="4" styleClass="width100" />
            </div>
            <div class="col-5 controlLabel">
              <label id="status">Status</label>
            </div>
            <div class="col-7">
              <p-dropdown id="ddStatus" [style]="{'width':'100%'}" name="breedingZoneRequestStatus" [filter]=true
                [options]="status" [(ngModel)]="breedingZoneForm.RequestStatus" placeholder="Select a Status"
                formControlName="RequestStatus" (onChange)="statusChange(breedingZoneForm.RequestStatus,true)"
                appendTo="body"></p-dropdown>
            </div>
            <div class="col-5 controlLabel">
              <label id="PRISMAOIID">PRISM AOI ID</label>
            </div>
            <div class="col-7">
              <input id="txtBZPRISMAOIID" type="number" pInputText id="PRISM_AOI_ID" [(ngModel)]="breedingZoneForm.PRISM_AOI_ID"
                formControlName="PRISM_AOI_ID" maxlength="9" styleClass="width100" readonly="readonly" />
            </div>
            <div class="col-5">
            </div>
            <div class="col-7">
              <button id="btnBZCheckAOI" type="button" pButton icon="fa fa-eye" class="p-button-info" (click)="GetAOIID(breedingZoneForm)"
                label="Check AOI" title="Check AOI"></button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </p-panel>
  <ng-template pTemplate="footer">
      <div [hidden]="!isROVUser" style="display: inline;">
        <button id="btnBZCancel" type="button" pButton icon="fa fa-close" class="p-button-warning" (click)="close(frm)"
          label="Cancel" style='width: 200px;'>
        </button>
      </div>
      <button id="btnBZSave" type="submit" pButton [icon]="saveIcon" class="{{saveIconClass}}" (click)="saveBreedingZone(frm, breedingZoneForm.RequestStatus)"
        [disabled]="!(frm.form.valid || breedingZoneForm.RequestStatus === 'Declined')" label="{{saveLabelText}}"
        style='width: 200px;'></button>
  </ng-template>
</p-dialog>

<p-dialog id="dlgBZLeads" header="Select Breeding Zone Leads" [style]="{'width': '1000px', 'height': '580px'}" [(visible)]="displayBreedingZoneLead"
  showEffect="fade" [modal]="true" [closeOnEscape]="true">
  <app-peoplepicker id="appBZPeoplePicker" [existingPeopleString]="existingPeopleString" (addUserDataClick)="onaddUserDataClick($event)"></app-peoplepicker>
</p-dialog>

<p-dialog id="dlgBZAOIID" header="Select AOI ID" [style]="{'top': positionTop, 'left': positionLeft, width: 'width', height:'height'}"
  [(visible)]="displayLEIDWindow" showEffect="fade" [modal]="true"
  [closeOnEscape]="true" [closable]="true" [resizable]="false">
  <app-leidpicker id="appBZLEID" [existingLEIDString]="existingLEIDString" (addLEDataClick)="onaddLEDataClick($event)"></app-leidpicker>
</p-dialog>
