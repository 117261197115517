.col-button {
    width: 10%;
    text-align: center;
}

.excelColor {
    color: #42d042;
}

.p-datatable .p-datatable-thead>tr>th {
    position: -webkit-sticky !important;
    position: sticky !important;
}

:host ::ng-deep .p-datatable .p-datatable-thead>tr>th {
    position: -webkit-sticky !important;
    position: sticky !important;
}

/* .layout-news-active :host ::ng-deep .p-datatable tr>th {
    top: 7rem !important;
} */

@media screen and (max-width: 64em) {
    :host ::ng-deep .p-datatable .p-datatable-thead>tr>th {
        top: 0px !important;
    }
}