<div id="dvSSLGrid" class="table-container">
  <p-table #dt [columns]="cols" id="dtSSL" [value]="sslAllData" dataKey="SeedStorageLocationID" sortMode="single"
      [reorderableColumns]="true" [scrollable]="true" [(selection)]="selectedItems"
      columnResizeMode="fit" exportFilename="SSL" (onFilter)="filterSSLDataTable($event)"
      selectionMode="single" (onRowDblclick)="dt.toggleRow($event)" [loading]="loading"
      [totalRecords]="sslAllData.length" [resizableColumns]="true" [expandedRowKeys]="expandedRows" [(first)]="first">
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix align-center">
        <p-button label="Seed Storage Location Count" icon="fa fa-location-arrow" styleClass="p-button-outlined p-button-secondary" badge="{{sslAllData.length}}" badgeClass="p-badge-secondary"></p-button>&nbsp;
        <p-button label="Filtered Count" icon="fa fa-filter" styleClass="p-button-outlined p-button-secondary" badge="{{filteredData.length}}" badgeClass="p-badge-secondary"></p-button>
        <button type="button" pButton icon="fa fa-file-o" class="p-button-success" iconPos="left" label="Export"
          (click)="exportCSVForSSL()" style="float:right"></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 1.25em"></th>
        <ng-container *ngFor="let col of columns">
          <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [pTooltip]="col.title ?? col.header">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </ng-container>
      </tr>
      <tr>
        <th *ngFor="let col of columns" [ngSwitch]="col.field">
          <p-multiSelect id="msSSLCodes" *ngSwitchCase="'Name'" [(ngModel)]="filteredSSLCodes"
            [resetFilterOnHide]="true" [options]="distinctCodes" placeholder="All SSLs"
            (click)="$event.stopPropagation();" appendTo="body" (onChange)="dt.filter($event.value,col.field,'in')"
            styleClass="ui-column-filter" [style]="{'width':'100%'}" [maxSelectedLabels]="3"
            selectedItemsLabel="{0} items selected"></p-multiSelect>
          <input id="txtFilterSSLStationCode" *ngSwitchCase="'Station.StationCode'" [(ngModel)]="filteredSSLCode"
            pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
          <input id="txtFilterSSLCrop" *ngSwitchCase="'CropDescription'" [(ngModel)]="filteredSSLCrop" pInputText
            type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
          <input id="txtFilterSSLCountry" *ngSwitchCase="'Country'" [(ngModel)]="filteredSSSLCountry" pInputText
            type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
          <input id="txtFilterSSLState" *ngSwitchCase="'State'" [(ngModel)]="filteredSSLState" pInputText type="text"
            (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
            placeholder="Search">
          <input id="txtFilterStationKeyContact" *ngSwitchCase="'SeedStorageLocationKeyContactNames'"
            [(ngModel)]="filteredSeedStorageLocationKeyContactNames" pInputText type="text"
            (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
            placeholder="Search">
          <input id="txtFilterStationStatus" *ngSwitchCase="'RequestStatus'" [(ngModel)]="filteredSSLRequestStatus"
            pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns" let-data>
      <tr [pSelectableRow]="rowData" (dblclick)="dt.toggleRow(rowData)">
        <td style="width: 1.25em">
          <a href="#" [pRowToggler]="rowData">
            <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-circle-down' : 'pi pi-pw pi-chevron-circle-right'"
              style="font-size: 1.25em"></i>
          </a>
        </td>
        <td [hidden]="true">{{data.Id}}</td>
        <td>{{data.Name}}</td>
        <td>{{data.Station.StationCode}}</td>
        <td>{{data.CropDescription}}</td>
        <td>{{data.Country}}</td>
        <td>{{data.State}}</td>
        <td>{{data.SeedStorageLocationKeyContactNames}}</td>
        <td>{{data.RequestStatus}}</td>
      </tr>
    </ng-template>
    <ng-template let-ssl pTemplate="rowexpansion" let-columns="columns">
      <tr>
        <td [attr.colspan]="columns.length">
          <div class="grid">
            <div class="col">
              <p-panel class="height">
                <div class="grid grid-nogutter">
                    <div class="col-6">
                      <label for="SSLName">SSL Name :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{ssl.Name}}</p>
                    </div>
                    <div class="col-6">
                      <label for="SSLName">SSL Type :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{ssl.SeedStorageLocationType ?
                        ssl.SeedStorageLocationType.Name : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label>Key Contact :</label>
                    </div>
                    <div class="col-6">
                      <p *ngFor="let item of ssl.SeedStorageLocationKeyContactNames.split(';')" style="white-space: pre-wrap">{{item}}</p>
                    </div>
                    <div class="col-6">
                      <label style="word-wrap: break-word">Description : </label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{ssl.Description}}</p>
                    </div>
                    <div class="col-6">
                      <label>Crop :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{ssl.CropDescription}}</p>
                    </div>
                    <div class="col-6">
                      <label>Status :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{ssl.RequestStatus}}</p>
                    </div>
                </div>
              </p-panel>
            </div>
            <div class="col">
              <p-panel class="height">
                <div class="grid grid-nogutter">
                  <div class="col-6">
                    <label>R&D Center :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{ssl.Station ? ssl.Station.StationCode : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>PRISM AOI ID :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{ssl.PRISM_AOI_ID ? ssl.PRISM_AOI_ID : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>Geographic Locale :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{ssl.Station.GeographicLocale}}</p>
                  </div>
                  <div class="col-6">
                    <label>Virtual :</label>
                  </div>
                  <div class="col-6">
                    <p style="word-wrap: break-word">{{ssl.IsVirtual}}</p>
                  </div>
                  <div class="col-6">
                    <label>R3 Building Code :</label>
                  </div>
                  <div class="col-6">
                    <p style="word-wrap: break-word">{{ssl.R3BuildingCode ? ssl.R3BuildingCode : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>WWS Shipping Project :</label>
                  </div>
                  <div class="col-6">
                    <p style="word-wrap: break-word">{{ssl.WWSShippingProject}}</p>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="col">
              <p-panel class="height">
                <div class="grid grid-nogutter">
                  <div class="col-6">
                    <label>PRISM LE ID :</label>
                  </div>
                  <div class="col-6">
                    <p style="word-wrap: break-word">{{ssl.PrismLeId ? ssl.PrismLeId : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>Created By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{ssl.CreatedByUserName ? ssl.CreatedByUserName : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>Date Created :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{ConvertDateToString(ssl.CreatedOn)}}</p>
                  </div>
                  <div class="col-6">
                    <label>Approved By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{ssl.ApprovedByUserName ? ssl.ApprovedByUserName : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>Date Approved :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{ConvertDateToString(ssl.ApprovedOn)}}</p>
                  </div>
                  <div class="col-6">
                    <label>Retired By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{ssl.RetiredByUserName ? ssl.RetiredByUserName : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>Date Retired :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{ConvertDateToString(ssl.RetiredOn)}}</p>
                  </div>
                  <div class="col-6">
                    <label>Last Modified By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{ssl.ModifiedByUserName ? ssl.ModifiedByUserName : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>Date Last Modified :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{ConvertDateToString(ssl.ModifiedOn)}}</p>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
          <div class="grid">
            <div class="col-8"></div>
            <div class="col-4">
              <div class="grid">
                <div class="col-4">
                  <div [hidden]="!((ssl.CreatedBy !== null && ssl.CreatedBy.Email.toLocaleLowerCase() === userName) || isROVAdmin)">
                    <button id="btnClone" pButton type="button" style="width: 100%" (click)="onCloneSSL(ssl)" label="Clone"
                      iconPos="left" icon="fa fa-fw fa-copy" class="p-button-info"></button>
                  </div>
                </div>
                <div class="col-4">
                  <button id="btnSSLComments" pButton type="button" style="width: 100%" (click)="viewComments(ssl)"
                    label="{{ssl.ApprovalComments === null || ssl.ApprovalComments.length === 0 ? 'Add Comment' : 'View / Add Comment(s)'}}"
                    iconPos="left" [icon]="ssl.ApprovalComments === null || ssl.ApprovalComments.length === 0 ? 'fa fa-pencil' : 'fa fa-comments-o'"
                    class="p-button-info"></button>
                </div>
                <div class="col-4">
                  <div [hidden]="!((ssl.CreatedBy !== null && ssl.CreatedBy.Email.toLocaleLowerCase() === userName) || isROVAdmin)">
                    <button id="btnSSLEdit" pButton type="button" style="width: 100%" (click)="editSSL(ssl)" label="Edit"
                      iconPos="left" icon="fa fa-fw fa-edit"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
          <td [attr.colspan]="8">
              No records found
          </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog header="View / Add Comments" [style]="{width: '900px', height:'470px'}" [(visible)]="displayComments"
  showEffect="fade" [modal]="true">
  <view-comments [disableCommentButton]="disableCommentButton" [approvalCommentData]="approvalCommentData"
   [sourceIdentifier]="sourceIdentifier" (saveCommentEvent)="onSaveComment($event)"></view-comments>
</p-dialog>

<p-dialog [(visible)]="dialogVisible" [contentStyle]="{'overflow':'visible'}" [breakpoints]="{'640px':'100vw'}"
    showEffect="fade" [modal]="true"  [style]="{width: '900px'}"
    (onShow)="onEditSSLShow()" (onHide)="onCloseEditSSL($event, frm)" [closeOnEscape]="false"
    [closable]="false" [showHeader]="true" header="Seed Storage Location Details">
  <p-panel appendTo="body">
    <form #frm="ngForm" [formGroup]="sslFormGroup">
      <div class="grid">
        <div class="col-6">
          <div class="grid">
            <div class="col-5 controlLabel">
              <label for="SSLName">SSL Name</label>
            </div>
            <div class="col-7">
              <input id="txtSSLName" pInputText id="sslName" [(ngModel)]="sslForm.Name"
                formControlName="SSLName" minlength="2" maxlength="4" styleClass="width100" />
            </div>
            <div class="col-5 controlLabel">
              <label for="lstSSLLeads">Key Contact</label>
            </div>
            <div class="col-7">
              <div class="grid">
                <div class="col-10">
                  <p-listbox id="lstSSLLeads" [options]="keyContactsLead" optionLabel="label"
                    [(ngModel)]="sslForm.SeedStorageLocationKeyContactNames" formControlName="KeyContacts"
                    styleClass="width100" [listStyle]="{'max-height':'100px'}">
                  </p-listbox>
                </div>
                <div class="col-2">
                  <button id="btnSSLAddLead" type="button" pButton icon="fa fa-user-plus" class="p-button-info"
                    style="width: 30px" (click)="addLeads()" title="Add Key Contacts"></button>
                </div>
              </div>
            </div>
            <div class="col-5 controlLabel">
              <label for="ddSSLCropName">Crop </label>
            </div>
            <div class="col-7">
              <p-dropdown id="ddSSLCropName" #dd [style]="{'width':'100%'}" name="CropName" [filter]=true
                [options]="sslCropName" [(ngModel)]="sslForm.CropID" formControlName="CropID"
                placeholder="Select a Crop" appendTo="body"
                (onChange)="clearStation(); getStationsByCropID();" [showClear]="true"></p-dropdown>
            </div>
            <div class="col-5 controlLabel">
              <label>R&D Center</label>
            </div>
            <div class="col-7">
              <p-dropdown [style]="{'width':'100%'}" id="ddSSLStationCode" name="Station" [filter]=true
                [options]="allStationName" [(ngModel)]="sslForm.StationId" appendTo="body"
                formControlName="StationId" placeholder="Select a R&D Center"
                [showClear]="true"></p-dropdown>
            </div>
            <div class="col-5 controlLabel">
              <label for="txtSSLDescription">Description</label>
            </div>
            <div class="col-7">
              <div class="ui-g-12 ui-md-12">
                <textarea [cols]="30" [rows]="2" id="txtSSLDescription" minlength="1" maxlength="255"
                  pInputTextarea [(ngModel)]="sslForm.Description" formControlName="Description" class="p-inputtext"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="grid">
            <div class="col-12">
              <div class="grid">
                <div class="col-5 controlLabel">
                  <label for="ddSSLType">SSL Type</label>
                </div>
                <div class="col-7">
                  <p-dropdown id="ddSSLType" [style]="{'width':'100%'}" name="SeedStorageLocationTypeId"
                    [filter]=true [options]="sslTypeName" [(ngModel)]="sslForm.SeedStorageLocationTypeId"
                    formControlName="SeedStorageLocationTypeId" placeholder="Select a SSL Type"
                    appendTo="body" [showClear]="true"></p-dropdown>
                </div>
              </div>
            </div>
            <div class="col-5 controlLabel">
              <label for="cbSSLVirtual">Virtual</label>
            </div>
            <div class="col-7">
              <p-checkbox id="cbSSLVirtual" name="virtual" [(ngModel)]="sslForm.IsVirtual"
                formControlName="Virtual" [binary]="true"></p-checkbox>
            </div>
            <div class="col-5 controlLabel">
              <label for="txtSSLR3BuildingCode">R3 Building Code </label>
            </div>
            <div class="col-7">
              <div class="ui-g-12 ui-md-12">
                <input pInputText id="txtSSLR3BuildingCode" [(ngModel)]="sslForm.R3BuildingCode"
                  formControlName="R3BuildingCode" styleClass="width100" maxlength="25" />
              </div>
            </div>
            <div class="col-5 controlLabel">
              <label for="txtWWSShippingProject">WWS Shipping Project</label>
            </div>
            <div class="col-7">
              <input type="text" pInputText id="txtWWSShippingProject" [(ngModel)]="sslForm.WWSShippingProject"
                formControlName="WWSShippingProject" maxlength="255" styleClass="width100" />
            </div>
            <div class="col-5 controlLabel">
              <label for="ddSSLStatus">Status</label>
            </div>
            <div class="col-7">
              <p-dropdown id="ddSSLStatus" [style]="{'width':'100%'}" name="RequestStatus" [filter]=true
                [options]="status" [(ngModel)]="sslForm.RequestStatus" formControlName="RequestStatus"
                placeholder="Select a Status" (onChange)="statusChange(sslForm.RequestStatus,true)"
                appendTo="body"></p-dropdown>
            </div>
          </div>
        </div>
      </div>
    </form>

  </p-panel>
  <ng-template pTemplate="footer">
    <div [hidden]="!isROVUser" style="display:inline">
      <button id="btnCancelSSL" type="button" pButton icon="fa fa-close" class="p-button-warning"
        (click)="close(frm)" label="Cancel" style='width: 200px;'>
      </button>
    </div>
    <button id="btnSaveSSL" type="submit" pButton [icon]="saveIcon" class="{{saveIconClass}}"
      (click)="saveSSL(frm, sslForm.RequestStatus)"
      [disabled]="!(frm.form.valid || sslForm.RequestStatus === 'Declined')" label="{{saveLabelText}}"
      style='width: 200px;'></button>
  </ng-template>
</p-dialog>

<p-dialog id="dlgSSLLead" header="Select Key Contacts" [style]="{'width': '1000px', 'height': '580px'}" [(visible)]="displayKeyContacts"
  showEffect="fade" [modal]="true" [closeOnEscape]="true">
  <app-peoplepicker id="appSSLPeoplePicker" [existingPeopleString]="existingPeopleString" (addUserDataClick)="onaddUserDataClick($event)"></app-peoplepicker>
</p-dialog>
