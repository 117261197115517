<div id="dvEvaluationZoneGrid" class="table-container">
  <p-table #dt [columns]="cols" id="dtEvaluationZones" [value]="evaluationZoneAllData" dataKey="EvaluationZoneID" sortMode="single"
      [reorderableColumns]="true" [scrollable]="true"
      columnResizeMode="fit" exportFilename="Evaluation Zone" (onFilter)="filterEvaluationZoneDataTable($event)"
      selectionMode="single" (onRowDblclick)="dt.toggleRow($event)" [loading]="loading"
      [totalRecords]="evaluationZoneAllData.length" [resizableColumns]="true" [expandedRowKeys]="expandedRows" [(first)]="first">
    <ng-template pTemplate="caption" id="dtEZHeader">
    <div class="ui-helper-clearfix align-center">
      <p-button label="Evaluation Zone Count" icon="fa fa-crop" styleClass="p-button-outlined p-button-secondary" badge="{{evaluationZoneAllData.length}}" badgeClass="p-badge-secondary"></p-button>&nbsp;
      <p-button label="Filtered Count" icon="fa fa-filter" styleClass="p-button-outlined p-button-secondary" badge="{{filteredData.length}}" badgeClass="p-badge-secondary"></p-button>
      <button id="btnEZExport" type="button" pButton icon="fa fa-file-o" class="p-button-success" iconPos="left" label="Export"
        (click)="exportCSVForEvaluationZones()" style="float:right"></button>
    </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 1.25em"></th>
          <ng-container *ngFor="let col of columns">
            <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [pTooltip]="col.title ?? col.header">
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </ng-container>
        </tr>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <p-multiSelect id="msFilterEZCode" *ngSwitchCase="'EvaluationZoneCode'" [(ngModel)]="filteredEZCodes" [resetFilterOnHide]="true" [options]="distinctCodes"
            placeholder="All EZs" (click)="$event.stopPropagation();" appendTo="body" (onChange)="dt.filter($event.value,col.field,'in')"
            styleClass="ui-column-filter" [style]="{'width':'100%'}" [maxSelectedLabels]="3" selectedItemsLabel="{0} items selected"></p-multiSelect>
          <input id="txtFilterEZName" *ngSwitchCase="'EvaluationZoneName'" [(ngModel)]="filteredEZName" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
          <input id="txtFilterEZCropName" *ngSwitchCase="'CropDescription'" [(ngModel)]="filteredEZCrops" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
          <p-multiSelect id="msFilterEZRegionCode" *ngSwitchCase="'RegionCode'"  [(ngModel)]="filteredEZRegionCodes" [resetFilterOnHide]="true" [options]="distinctRegions"
            placeholder="All RCZs" (click)="$event.stopPropagation();" appendTo="body" (onChange)="dt.filter($event.value,col.field,'in')"
            styleClass="ui-column-filter" [style]="{'width':'100%'}" [maxSelectedLabels]="3" selectedItemsLabel="{0} items selected"></p-multiSelect>
          <input id="txtFilterEZDescription" *ngSwitchCase="'Description'" [(ngModel)]="filteredEZDEscription" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
          <input id="txtFilterEZLead" *ngSwitchCase="'EvaluationZoneLeadNames'" [(ngModel)]="filteredEZLeadNames"
            pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
            placeholder="Search">
          <input id="txtFilterEZStatus" *ngSwitchCase="'RequestStatus'" [(ngModel)]="filteredEZRequestStatus" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
        <tr [pSelectableRow]="rowData" (dblclick)="dt.toggleRow(rowData)">
          <td style="width: 1.25em">
            <a href="#" [pRowToggler]="rowData">
              <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-circle-down' : 'pi pi-pw pi-chevron-circle-right'" style="font-size: 1.25em"></i>
            </a>
          </td>
          <ng-container *ngFor="let col of columns">
            <td *ngIf="!col.hidden">
              {{rowData[col.field]}} {{rowData["Region.Code"]}}
            </td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template let-evaluationZone pTemplate="rowexpansion" id="rowBZexpand" let-columns="columns">
        <tr>
          <td [attr.colspan]="columns.length">
            <div class="grid">
              <div class="col">
                <p-panel class="height" id="editEZPanel1">
                  <div class="grid grid-nogutter">
                    <div class="col-6">
                      <label id="lblEZName">Evaluation Zone Name :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valEZName">{{evaluationZone.EvaluationZoneName}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblEZCode">Evaluation Zone Code :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valEZCode">{{evaluationZone.EvaluationZoneCode}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblEZLead">Evaluation Zone Lead :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valEZLead" *ngFor="let item of evaluationZone.EvaluationZoneLeadNames.split(';')">{{item ? item : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblDescription">Description :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valDescription">{{evaluationZone.Description}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblCropDescription">Crop :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valCropDescription">{{evaluationZone.CropDescription}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblStatus">Status :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valStatus">{{evaluationZone.RequestStatus}}</p>
                    </div>
                  </div>
                </p-panel>
              </div>
              <div class="col">
                <p-panel class="height" id="editPanel2">
                  <div class="grid grid-nogutter">
                    <div class="col-6">
                      <label id="lblEZType">Evaluation Zone Type :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valEZTypeCode">{{evaluationZone.EvaluationZoneTypeCode}} -
                        {{evaluationZone.EvaluationZoneTypeName}}</p>
                    </div>
                    <div class="col-6">
                      <label style="word-wrap: break-word" id="lblColor">Color : </label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valColor">{{evaluationZone.Color ? evaluationZone.Color : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblProgram">Program :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valProgram">{{evaluationZone.Programs ? evaluationZone.Programs : '-'}}</p>
                    </div>
                  </div>
                </p-panel>
              </div>
              <div class="col">
                <p-panel class="height" id="editPanel3">
                  <div class="grid grid-nogutter">
                    <div class="col-6">
                      <label id="lblAOIID">PRISM AOI ID :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valAOIID">{{evaluationZone.PRISM_AOI_ID ? evaluationZone.PRISM_AOI_ID : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblCreatedBy">Created By :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valCreatedBy">{{evaluationZone.CreatedByUserName ? evaluationZone.CreatedByUserName : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblDate">Date Created :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valDate">{{ConvertDateToString(evaluationZone.CreatedOn)}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblApprovedBy">Approved By :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valApprovedBy">{{evaluationZone.ApprovedByUserName ? evaluationZone.ApprovedByUserName : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblDateApproved">Date Approved :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valDateApproved">{{ConvertDateToString(evaluationZone.ApprovedOn)}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblRetiredBy">Retired By :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valRetiredBy">{{evaluationZone.RetiredByUserName ? evaluationZone.RetiredByUserName : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblDateRetired">Date Retired :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valDateRetired">{{ConvertDateToString(evaluationZone.RetiredOn)}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblLastModifiedBy">Last Modified By :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valLastModifiedBy">{{evaluationZone.ModifiedByUserName ? evaluationZone.ModifiedByUserName : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblModifiedOn">Date Last Modified :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valModifiedOn">{{ConvertDateToString(evaluationZone.ModifiedOn)}}</p>
                    </div>
                  </div>
                </p-panel>
              </div>
            </div>
            <div class="grid">
              <div class="col-8"></div>
              <div class="col-4">
                <div class="grid">
                  <div class="col-4">
                    <div
                      [hidden]="!((evaluationZone.CreatedBy !== null && evaluationZone.CreatedBy.Email.toLocaleLowerCase() === userName) || isROVAdmin)">
                      <button id="btnClone" pButton type="button" style="width: 100%"
                        (click)="onCloneEvaluationZone(evaluationZone)" label="Clone" iconPos="left" icon="fa fa-fw fa-copy"
                        class="p-button-info"></button>
                    </div>
                  </div>
                  <div class="col-4">
                    <button id="btnEZComment" pButton type="button" style="width: 100%" (click)="viewComments(evaluationZone)"
                      label="{{evaluationZone.ApprovalComments === null || evaluationZone.ApprovalComments.length === 0 ? 'Add Comment' : 'View / Add Comment(s)'}}"
                      iconPos="left"
                      [icon]="evaluationZone.ApprovalComments === null || evaluationZone.ApprovalComments.length === 0 ? 'fa fa-pencil' : 'fa fa-comments-o'"
                      class="p-button-info"></button>
                  </div>
                  <div class="col-4">
                    <div
                      [hidden]="!((evaluationZone.CreatedBy !== null && evaluationZone.CreatedBy.Email.toLocaleLowerCase() === userName) || isROVAdmin)">
                      <button id="btnEZEdit" pButton type="button" style="width: 100%" (click)="editEvaluationZones(evaluationZone)"
                        label="Edit" iconPos="left" icon="fa fa-fw fa-edit"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="7">
                No records found
            </td>
        </tr>
      </ng-template>
</p-table>
</div>

<p-dialog id="dlgBZComments" header="View / Add Comments" [style]="{width: '900px', height:'470px'}" [(visible)]="displayComments"
  showEffect="fade" [modal]="true">
  <view-comments [disableCommentButton]="disableCommentButton" [approvalCommentData]="approvalCommentData"
   [sourceIdentifier]="sourceIdentifier" (saveCommentEvent)="onSaveComment($event)"></view-comments>
</p-dialog>

<div>
  <p-dialog id="dlgEZEdit" [contentStyle]="{'overflow':'visible'}" [(visible)]="dialogVisible" [breakpoints]="{'640px':'100vw'}"
    showEffect="fade" [modal]="true" [style]="{width: '900px'}" (onHide)="onCloseEditEvaluationZone($event, frm)"
    [closeOnEscape]="false" [closable]="false" [showHeader]="true" header="Evaluation Zone Details">
    <p-panel id="editEZPanel4" appendTo="body">
      <form #frm="ngForm" id="frmEZ" [formGroup]="evaluationZoneFormGroup">
        <div class="grid">
          <div class="col-6">
            <div class="grid">
                <div class="col-5 controlLabel">
                  <label id="lblEvaluationZoneName">Evaluation Zone Name</label>
                </div>
                <div class="col-7">
                  <input pInputText id="txtEvaluationZoneName" [(ngModel)]="evaluationZoneForm.EvaluationZoneName"
                    formControlName="EvaluationZoneName" minlength="1" maxlength="100" styleClass="width100" />
                </div>
                <div class="col-5 controlLabel">
                  <label id="lblEvaluationZoneCode">Evaluation Zone Code</label>
                </div>
                <div class="col-7">
                  <input pInputText id="txtEZCode" minlength="2" maxlength="4" [(ngModel)]="evaluationZoneForm.EvaluationZoneCode"
                    formControlName="EvaluationZoneCode" styleClass="width100" (keydown)="evaluationZoneForm.PRISM_AOI_ID = ''" />
                </div>
                <div class="col-5 controlLabel">
                  <label id="lblEditCrop">Crop</label>
                </div>
                <div class="col-7">
                  <p-dropdown id="ddEZCrop" [style]="{'width':'100%'}" name="Crop" [filter]=true [options]="evaluationZoneCropName"
                    [(ngModel)]="evaluationZoneForm.CropID" formControlName="CropID" placeholder="Select a Crop"
                    (onChange)="clearRegion(); getRegionsByCropID()" [showClear]="true"></p-dropdown>
                </div>
                <div class="col-5 controlLabel">
                  <label id="lblDes">Description</label>
                </div>
                <div class="col-7">
                  <textarea id="txtEZDescription" [cols]="30" [rows]="2" minlength="1" maxlength="2000" [(ngModel)]="evaluationZoneForm.Description"
                    formControlName="Description" pInputTextarea styleClass="width100" class="p-inputtext"></textarea>
                </div>
                <div class="col-5 controlLabel">
                  <label id="color">Color</label>
                </div>
                <div class="col-7">
                  <input type="number" pInputText id="txtEZColor" [(ngModel)]="evaluationZoneForm.Color"
                    formControlName="Color" maxlength="9" styleClass="width100" />
                </div>
                <div class="col-5 controlLabel">
                  <label for="ddEZRegionName">RCZ Name</label>
                </div>
                <div class="col-7">
                  <p-dropdown id="ddEZRegionName" [style]="{'width':'100%'}" appendTo="body" name="regionName"
                    [filter]=true [options]="allRegions" [(ngModel)]="evaluationZoneForm.RegionID" formControlName="RegionID"
                    placeholder="Select a RCZ" [showClear]="true"></p-dropdown>
                </div>
            </div>
          </div>
          <div class="col-6">
            <div class="grid">
              <div class="col-5 controlLabel">
                <label for="ddEZType">Evaluation Zone Type</label>
              </div>
              <div class="col-7">
                <p-dropdown id="ddEZType" [style]="{'width':'100%'}" name="evaluationZoneType" [filter]=true
                [options]="evaluationZoneTypeName" [(ngModel)]="evaluationZoneForm.EvaluationZoneTypeID"
                formControlName="EvaluationZoneTypeID" placeholder="Select a Evaluation Zone Type" [showClear]="true"></p-dropdown>
              </div>
              <div class="col-5 controlLabel">
                <label for="lstEZLead">Evaluation Zone Lead</label>
              </div>
              <div class="col-7">
                <div class="grid">
                  <div class="col-10">
                    <p-listbox id="lstEZLead" [options]="ezLead" optionLabel="label" [(ngModel)]="evaluationZoneForm.EvaluationZoneLeadNames"
                      formControlName="EvaluationZoneLeadNames" styleClass="width100" [style]="{'min-height':'35px'}"
                      [listStyle]="{'max-height':'100px'}">
                    </p-listbox>
                  </div>
                  <div class="col-2">
                    <button id="btnEZAddLead" type="button" pButton icon="fa fa-user-plus" class="p-button-info"
                      style="width: 30px" (click)="addLeads()" title="Add Evaluation Zone Lead"></button>
                  </div>
                </div>
              </div>
              <div class="col-5 controlLabel">
                <label for="txtEZProgram">Program</label>
              </div>
              <div class="col-7">
                <textarea [cols]="30" [rows]="2" id="txtEZProgram" readonly="readonly" [(ngModel)]="evaluationZoneForm.Programs"
                  formControlName="Programs" styleClass="width100" pInputTextarea styleClass="width100" class="p-inputtext"></textarea>
              </div>
              <div class="col-5 controlLabel">
                <label id="status">Status</label>
              </div>
              <div class="col-7">
                <p-dropdown id="ddEZStatus" [style]="{'width':'100%'}" name="status" [filter]=true [options]="status"
                  [(ngModel)]="evaluationZoneForm.RequestStatus" formControlName="RequestStatus" placeholder="Select a Status"
                  (onChange)="statusChange(evaluationZoneForm.RequestStatus,true)" appendTo="body"></p-dropdown>
              </div>
              <div class="col-5 controlLabel">
                <label id="lblPrismAoiId">PRISM AOI ID</label>
              </div>
              <div class="col-7">
                <input type="number" pInputText id="txtEZPRISM_AOI_ID" [(ngModel)]="evaluationZoneForm.PRISM_AOI_ID"
                  formControlName="PRISM_AOI_ID" maxlength="9" styleClass="width100" readonly="readonly" />
              </div>
              <div class="col-5">
              </div>
              <div class="col-7">
                <button type="button" id="btnEZCheckAOI" pButton icon="fa fa-eye" class="p-button-info" (click)="GetAOIID(evaluationZoneForm)"
                  label="Check AOI" title="Check AOI"></button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </p-panel>
    <ng-template pTemplate="footer">
        <div [hidden]="!isROVUser" style="display: inline;">
          <button type="button" id="btnEZCancel" pButton icon="fa fa-close" class="p-button-warning" (click)="close(frm)"
            label="Cancel" style='width: 200px;'>
          </button>
        </div>
        <button type="submit" id="btnEZSave" pButton [icon]="saveIcon" class="{{saveIconClass}}" (click)="saveEvaluationZone(frm, evaluationZoneForm.RequestStatus)"
          [disabled]="!(frm.form.valid || evaluationZoneForm.RequestStatus === 'Declined')" label="{{saveLabelText}}"
          style='width: 200px;'></button>
    </ng-template>
  </p-dialog>
</div>

<p-dialog id="dlgEZLeads" header="Select EZ Leads" [style]="{'width': '1000px', 'height': '580px'}" [(visible)]="displayEvaluationZoneLead"
  showEffect="fade" [modal]="true" [closeOnEscape]="true">
  <app-peoplepicker id="appEZPeoplePicker" [existingPeopleString]="existingPeopleString" (addUserDataClick)="onaddUserDataClick($event)"></app-peoplepicker>
</p-dialog>

<p-dialog id="dlgEZSelectAOI" header="Select AOI ID" [style]="{'top': positionTop, 'left': positionLeft, width: 'width', height:'height'}"
  [(visible)]="displayLEIDWindow" showEffect="fade" [modal]="true"
  [closeOnEscape]="true" [closable]="true" [resizable]="false">
  <app-leidpicker id="appEZLEID" [existingLEIDString]="existingLEIDString" (addLEDataClick)="onaddLEDataClick($event)"></app-leidpicker>
</p-dialog>
