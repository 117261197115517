.rightAlign {
  float: right;
}

.centretAlign {
  float: centre;
}

::ng-deep .ui-datatable-scrollable-view .ui-datatable-scrollable-body {
  /* min-height: 200px; */
  border: 1px solid #D5D5D5;
}

.gridHeight .ui-datatable .ui-datatable-scrollable-body {
  max-height: 220px;
  min-height: 150px;
  border: 1px solid #D5D5D5;
}

.closeIconWidth.ui-button {
  width: 26px;
}

#dataTableADUser .ui-datatable .ui-datatable-header,
.ui-datatable .ui-datatable-caption,
.ui-datatable .ui-datatable-footer {
  padding: 0;
}

#panelPeoplePicker .ui-panel .ui-panel-footer {
  padding: 0;
}

.alignColumnText {
  text-align: center
}

.width20 {
  width: 20%;
}

.width20px {
  width: 20px;
}

.width35 {
  width: 35%;
}

