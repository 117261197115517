.p-fileupload-content {
  min-height: 50px;
  border: 4px dashed #ccc !important;
  padding: 0 !important;
}

.p-panel .p-panel-content {
  padding-bottom: 1rem !important;
}

.p-fileupload-buttonbar p-button:first-of-type{
  float:right;
}

.p-fileupload-buttonbar > span{
  margin-right: 48em !important;
}

/* .panelheight{
    height: 100%;
}*/

.width .ui-panel.ui-widget {
  height: 285px;
}

.height .ui-panel.ui-widget {
  height: 485px;
}

.ui-fileupload-buttonbar .ui-fileupload-choose {
  margin-right: 48em;
}

.width12 {
  width: 12%;
}


/* .verticaltext .ui-column-title {
  width: 1px;
  word-wrap: break-word;
} */