.width30 {
  width: 30%;
}

.width40 {
  width: 40%;
}

#userSearchDiv [class=col-2], #userSearchDiv [class=col-1] {
  padding: 2px !important;
}